<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878E99; height:1.5rem"
      />
      <div style="width: 250px">
        <h1
          class="text-xl text-left font-extrabold ml-4 mt-3"
        >
          Consolidated Leave
        </h1>
      </div>
      <div class="w-7/12 mt-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div class="flex flex-col w-full">
      <card class="w-full flex flex-col pt-0 p-5">
        <div class="w-full gap-5 mb-6 flex" />
        <template v-slot:footer>
          <CardFooter>
            <template v-slot:search>
              <SearchInput
                placeholder="Search"
                class="search"
                style="width : 300px; margin-top: 0.1rem;"
              />
            </template>
            <template v-slot:selection>
              <CSelect
                placeholder="All Locations"
                variant="w-full h-10"
                class="h-10 mr-2"
                style="margin-top: -0.4rem"
              />
            </template>
            <template v-slot:calendar>
              <div
                class="flex"
                style="width: 180px; padding: 5px 8px;"
              >
                <icon
                  icon-name="calendar"
                  class="icon mt-1"
                />
                <div
                  class="border-r"
                  style="width: 1px; margin: 8px 8px; border-color: #321C3B;"
                />
                <div
                  class="text-darkPurple h-full"
                  style="width: 91px; font-size: 16px; margin: 4px 6px"
                >
                  Year to Date
                </div>
                <icon
                  icon-name="caret-down"
                  class="icon mt-1"
                />
              </div>
            </template>
          </CardFooter>
        </template>
      </card>
      <card class="p-5 mt-1">
        <div class="flex-col">
          <details class="w-full flex h-auto mb-2">
            <summary class="w-full flex items-center justify-between py-2 h-12">
              <div class="w-2/12 text-lg font-bold text-darkPurple">
                Leave Schedule
              </div>
              <div
                class="border border-dashed mr-5 mt-1 ml-8 h-1 w-11/12"
              />
              <div
                class="flex w-1/12 cursor-pointer text-right"
              >
                <icon
                  icon-name="chevronDown"
                  size="xms"
                  class="mr-2 ml-5"
                />
                <p class="text-base font-bold text-darkPurple">
                  Expand
                </p>
              </div>
            </summary>
            <div
              class="w-full border p-5"
              style="border-radius: 8px;"
            >
              <div
                class="border w-full my-2"
                style="border-color: #878E99; height: 1px;"
              />
              <Card
                class="w-3/6 py-6 px-4"
              >
                <div class="flex justify-between">
                  <div class="flex flex-col">
                    <span class="text-xs font-semibold uppercase text-jet">Consolidated</span>
                    <span class="text-lg font-extrabold text-darkPurple">8</span>
                  </div>
                  <div class="border bg-white w-0" />
                  <div class="flex flex-col">
                    <span class="text-xs font-semibold uppercase text-jet">Employee on Leave</span>
                    <span class="text-lg font-extrabold text-darkPurple">4</span>
                  </div>
                  <div class="border bg-white w-0" />
                  <div class="flex flex-col">
                    <span class="text-xs font-semibold uppercase text-jet">Total Leave Days</span>
                    <span class="text-lg font-extrabold text-darkPurple">5</span>
                  </div>
                </div>
              </Card>
              <Table
                :headers="advanceHeader"
                :items="advanceData"
                class="w-full mt-4 mb-4"
                :has-checkbox="false"
                :loading="false"
              />
              <div
                class="border w-full mt-8 mb-2"
                style="border-color: #878E99; height: 1px;"
              />
            </div>
          </details>
          <details class="w-full flex h-auto mb-2">
            <summary class="w-full flex items-center justify-between py-2 h-12">
              <div class="w-2/12 text-lg font-bold text-darkPurple">
                Leave Allowance
              </div>
              <div
                class="border border-dashed mr-5 mt-1 ml-8 h-1 w-11/12"
              />
              <div
                class="flex w-1/12 cursor-pointer text-right"
              >
                <icon
                  icon-name="chevronDown"
                  size="xms"
                  class="mr-2 ml-5"
                />
                <p class="text-base font-bold text-darkPurple">
                  Expand
                </p>
              </div>
            </summary>
            <div
              class="w-full border p-5"
              style="border-radius: 8px;"
            >
              <div
                class="border w-full my-2"
                style="border-color: #878E99; height: 1px;"
              />

              <Card
                class="w-3/6 py-6 px-4"
              >
                <div class="flex justify-between">
                  <div class="flex flex-col">
                    <span class="text-xs font-semibold uppercase text-jet">Consolidated</span>
                    <span class="text-lg font-extrabold text-darkPurple">8</span>
                  </div>
                  <div class="border bg-white w-0" />
                  <div class="flex flex-col">
                    <span class="text-xs font-semibold uppercase text-jet">Employee on Leave</span>
                    <span class="text-lg font-extrabold text-darkPurple">4</span>
                  </div>
                  <div class="border bg-white w-0" />
                  <div class="flex flex-col">
                    <span class="text-xs font-semibold uppercase text-jet">Total Leave Days</span>
                    <span class="text-lg font-extrabold text-darkPurple">5</span>
                  </div>
                </div>
              </Card>
              <Table
                :headers="advanceHeader"
                :items="advanceData"
                class="w-full mt-4 mb-4"
                :has-checkbox="false"
                :loading="false"
              />
              <div
                class="border w-full mt-8 mb-2"
                style="border-color: #878E99; height: 1px;"
              />
            </div>
          </details>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import SearchInput from '@scelloo/cloudenly-ui/src/components/search-input';
import CSelect from '@scelloo/cloudenly-ui/src/components/select';
import Table from '@scelloo/cloudenly-ui/src/components/table';
import BackButton from '@scelloo/cloudenly-ui/src/components/back-button';
import Breadcrumb from '@scelloo/cloudenly-ui/src/components/bread-crumb';
import Card from '@/components/Card';
import Icon from '@/components/Icon';
import CardFooter from '@/components/CardFooter';

export default {
  name: "Advance",
  components: {
    SearchInput,
    CSelect,
    Card,
    Icon,
    CardFooter,
    Table,
    Breadcrumb,
    BackButton,
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false, text: "Benefits", href: "benefits", id: "Benefits",
        },
        {
          disabled: false, text: "Leave", id: "Advance and Loans",
        },
        {
          disabled: false, text: "Consolidated Leave", id: "Advance and Loans",
        },
      ],
      advanceHeader: [
        { title: 'EMPLOYEE', value: 'employee' },
        { title: 'Manager', value: 'Manager' },
        { title: 'Leave Balance', value: 'Leave Balance' },
        { title: 'Days Requested', value: 'Days Requested' },
        { title: 'Allowance Request', value: 'Allowance Request' },
        { title: 'Allowance Due', value: 'Allowance Request' },
        { title: 'Status', value: 'Allowance Request' },
        { title: '', value: 'menu', image: true },
      ],
      advanceData: [

      ],
    };
  },
};
</script>

<style scoped>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
</style>
